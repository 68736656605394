<template>
  <div>
    <el-popover
      ref="popover"
      v-model="popoverVisible"
      placement="bottom"
      width="200"
      trigger="manual"
    >
      <div>
        <el-checkbox-group v-model="selections">
          <div
            v-for="choice in options.choices"
            :key="choice.value"
          >
            <el-checkbox :label="choice.value">
              {{ choice.label }}
            </el-checkbox>
          </div>
        </el-checkbox-group>
      </div>
      <div class="buttons">
        <el-button
          :size="size"
          @click="handleCancel"
        >
          Cancel
        </el-button>
        <el-button
          :size="size"
          type="primary"
          @click="handleApply"
        >
          Apply
        </el-button>
      </div>
    </el-popover>
    <el-button
      v-popover:popover
      icon="fa fa-fw fa-filter"
      plain
      :size="size"
      type="primary"
      @click="togglePopover"
    >
      {{ buttonLabel }}
    </el-button>
  </div>
</template>

<script>
export default {
  props: {
    filter: { type: Array, required: true },
    options: { type: Object, required: true },
    size: { type: String, default: 'mini' },
  },

  data() {
    return {
      popoverVisible: false,
      selections: [],
    };
  },

  computed: {
    buttonLabel() {
      return (
        `${this.options.label}: ` +
        (this.filter.length === 0 ||
        this.options.choices.length === this.filter.length
          ? 'Any'
          : this.filter.join(', '))
      );
    },
  },

  methods: {
    handleApply() {
      this.$emit('update:filter', this.selections);
      this.popoverVisible = false;
    },

    handleCancel() {
      this.popoverVisible = false;
    },

    togglePopover() {
      if (!this.popoverVisible) {
        this.selections = this.filter;
      }

      this.popoverVisible = !this.popoverVisible;
    },
  },
};
</script>

<style scoped>
.buttons {
  margin-top: 1em;
}
</style>
