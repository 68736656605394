<template>
  <div>
    <data-table
      ref="dataTable"
      :actions="dataTable.actions"
      :data-class="item"
      :default-sort="{ sortBy: dataTable.sortBy, order: dataTable.order }"
      disable-action-menu
      disable-checkboxes
      disable-search
      :filters="{ statuses: statusFilter }"
    >
      <div slot="additional-controls">
        <filter-menu
          :filter.sync="statusFilter"
          :options="filterOptions"
        />
      </div>
      <el-table-column
        label="Name"
        prop="name"
      />
      <el-table-column label="Address">
        <template slot-scope="{ row }">
          {{ row.street_address }}, {{ row.city }}, {{ row.state }}
          {{ row.zip }}
        </template>
      </el-table-column>
      <el-table-column label="Notes">
        <template slot-scope="{ row }">
          {{ row.notes }}
          <span
            v-if="row.process_note"
            class="danger"
          >
            (ERROR: {{ row.process_note }})
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Start">
        <template slot-scope="{ row }">
          {{ row.start_date | dateString }}
        </template>
      </el-table-column>
      <el-table-column label="End">
        <template slot-scope="{ row }">
          {{ row.end_date | dateString }}
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        label="Status"
        sortable="custom"
      />
      <el-table-column>
        <template slot-scope="{ row }">
          <el-button
            v-if="row.status !== 'COMPLETE'"
            size="mini"
            type="primary"
            @click="handleProcess(row)"
          >
            Process
          </el-button>
        </template>
      </el-table-column>
    </data-table>
  </div>
</template>

<script>
import { order as orderApi } from '@/adonis-api';
import DataTable from '../global/DataTable.vue';
import FilterMenu from '../global/FilterMenu.vue';

export default {
  name: 'OrderProcessTable',
  components: { DataTable, FilterMenu },
  props: {
    orderId: { type: String, required: true },
    selectedRow: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dataTable: {
        actions: [],
        order: 'ascending',
        sortBy: 'status',
      },
      filterOptions: Object.freeze({
        label: 'Status',
        prop: 'status',
        choices: [
          {
            label: 'Pending',
            value: 'PENDING',
          },
          {
            label: 'Complete',
            value: 'COMPLETE',
          },
          {
            label: 'Error',
            value: 'ERROR',
          },
        ],
      }),
      item: Object.freeze({
        find: options => {
          return orderApi.getItems(this.orderId, options);
        },
      }),
      statusFilter: ['PENDING'],
    };
  },

  created() {
    this.stopSub = this.$store.subscribeAction(async action => {
      if (action.type === 'event') {
        if (action.payload.eventType === 'ORDER_PROCESSED') {
          const { itemId, orderId } = action.payload;

          await orderApi.updateOrderItem(itemId, {
            orderId,
            status: 'COMPLETE',
          });
          this.$refs.dataTable.invokeQuery();
        } else if (action.payload.eventType === 'ORDER_REPORTED') {
          this.$refs.dataTable.invokeQuery();
        }
      }
    });
  },

  beforeDestroy() {
    this.stopSub && this.stopSub();
  },

  methods: {
    handleProcess(item) {
      this.$emit('update:selectedRow', item);
      this.$emit('process-clicked');
    },
  },
};
</script>

<style scoped>
</style>
