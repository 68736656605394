<template>
  <div>
    <h3>Order Queue</h3>
    <order-process-table
      :order-id="$route.params.id"
      :selected-row.sync="selectedRow"
      @process-clicked="scrollToMap"
    />
    <div
      ref="scrollTarget"
      class="bordered"
    >
      <el-popover
        ref="geoframe-info"
        placement="bottom"
        title="Create Geoframe"
        width="200"
        trigger="hover"
        content="Draw a polygon, get device count, save to your geoframe library. Search for an address below to get started."
      />
      <el-row :gutter="20">
        <el-col
          :span="24"
          class="my-map"
        >
          <page-geoframe-create
            ref="geoframeCreator"
            is-order
            @save="handleSaveGeoframe"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import OrderProcessTable from '../../orders/OrderProcessTable';
import PageGeoframeCreate from '../geoframes/PageGeoframeCreate.vue';
import { order as orderApi } from '@/adonis-api';

export default {
  name: 'PageOrderQueue',
  components: {
    OrderProcessTable,
    PageGeoframeCreate,
  },
  data() {
    return {
      order: {},
      selectedRow: {},
    };
  },

  computed: {
    location() {
      if (this.selectedRow && this.selectedRow.id) {
        return `${this.selectedRow.streetAddress}, ${this.selectedRow.city}, ${
          this.selectedRow.state
        }`;
      }
      return '';
    },
  },

  async created() {
    try {
      this.order = await orderApi.findOne(this.$route.params.id);
    } catch (e) {
      this.$notify.error({
        message:
          'There was a problem fetching this order. The issue has been reported.',
      });
      this.$reportError(e);
    }
  },

  methods: {
    deselectRow() {
      this.selectedRow = {};
    },
    handleSaveGeoframe() {},
    scrollToMap() {
      const {
        name,
        street_address: address,
        city,
        state,
        zip,
        start_date: startDate,
        end_date: endDate,
      } = this.selectedRow;

      this.$store.dispatch('geoframes/populateOrderItem', {
        orderId: this.order.id,
        orderItemId: this.selectedRow.id,
        name,
        address: `${address}, ${city}, ${state} ${zip}`,
        organization: this.order.organization,
        startDate,
        endDate,
        tags: [
          ...(this.order ? this.order.tags : []),
          ...(this.selectedRow.tags
            ? this.selectedRow.tags.map(tag => ({ name: tag }))
            : []),
        ],
      });
      this.$scrollTo(this.$refs.scrollTarget);
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  font-weight: 300;
  font-size: 26px;
  margin: 0 0 5px !important;
  small {
    font-size: 0.75rem;
    text-transform: uppercase;
    color: #666;
  }
}

.my-map {
  position: relative;
}

.el-col:last-child {
  padding-right: 0 !important;
}

.bordered {
  margin-top: 1rem;
  padding: 15px 20px;
  border: 1px solid #eee;
  background: #fafafa;
  height: calc(55vh + 116px);
}
</style>
